/** @jsx jsx */
import { jsx } from 'theme-ui'
import Inner from '../../elements/inner'
import Content from '../../elements/content'
import { RBody } from './RBody'

export const RecruitmentBody = ({
  top,
  height,
}: {
  top: string
  height: string
}) => (
  <div>
    <Content sx={{ top, height }}>
      <Inner>
        <RBody />
      </Inner>
    </Content>
  </div>
)

/** @jsx jsx */
import React from 'react'
import { jsx, Box } from 'theme-ui'

export const RBody = () => {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          marginBottom: '200px',
        }}
      >
        <Box
          sx={{
            fontSize: ['2rem', '2rem', '3rem'],
            fontWeight: 700,
            lineHeight: 1.25,
            letterSpacing: '0.025em',
            color: 'text',
            textAlign: ['center'],
            flex: '1',
          }}
        >
          WELCOME TO
          <br /> NEW CHALLENGER
        </Box>

        <Box
          sx={{
            fontSize: ['1.5rem', '1.5rem', '1.5rem'],
            fontWeight: 600,
            lineHeight: 1.25,
            letterSpacing: '0.025em',
            color: 'skyblue',
            textAlign: ['center'],
            flex: '1',
            paddingBottom: '100px',
          }}
        >
          積極採用中
        </Box>

        {/* ここからマーク １行目*/}
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              whiteSpace: 'nowrap',
              fontSize: ['0.7rem', '0.8rem', '0.9rem'],
            }}
          >
            <Box
              sx={{
                width: ['48%', '48%', '48%', '23%', '23%', '23%'],
                textAlign: 'center',
                paddingBottom: '50px',
              }}
            >
              <img
                src="/systemengineer.png"
                alt="systemengineer"
                sx={{
                  objectFit: 'contain',
                  maxWidth: '100%',
                }}
              />
              <p sx={{ fontWeight: '600' }}>システムエンジニア</p>
            </Box>
            <Box
              sx={{
                width: ['48%', '48%', '48%', '23%', '23%', '23%'],
                textAlign: 'center',
              }}
            >
              <img
                src="/programmer.png"
                alt="programmer"
                sx={{
                  objectFit: 'contain',
                  maxWidth: '100%',
                }}
              />
              <p sx={{ fontWeight: '600' }}>プログラマー</p>
            </Box>

            <Box
              sx={{
                width: ['48%', '48%', '48%', '23%', '23%', '23%'],
                textAlign: 'center',
              }}
            >
              <img
                src="/projectmanager.png"
                alt="projectmanager"
                sx={{
                  objectFit: 'contain',
                  maxWidth: '100%',
                }}
              />
              <p sx={{ fontWeight: '600' }}>プロジェクト マネージャー</p>
            </Box>
            <Box
              sx={{
                width: ['48%', '48%', '48%', '23%', '23%', '23%'],
                textAlign: 'center',
              }}
            >
              <img
                src="/Webengineer.png"
                alt="Webengineer"
                sx={{
                  objectFit: 'contain',
                  maxWidth: '100%',
                }}
              />
              <p sx={{ fontWeight: '600' }}>WEBエンジニア</p>
            </Box>
          </Box>
        </Box>

        {/* ここからマーク ２行目*/}
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              whiteSpace: 'nowrap',
              fontSize: ['0.7rem', '0.8rem', '0.9rem'],
            }}
          >
            <Box
              sx={{
                width: ['48%', '48%', '48%', '23%', '23%', '23%'],
                textAlign: 'center',
                paddingBottom: '50px',
              }}
            >
              <img
                src="/front.png"
                alt="front"
                sx={{
                  objectFit: 'contain',
                  maxWidth: '100%',
                }}
              />
              <p sx={{ fontWeight: '600' }}>フロントエンド</p>
            </Box>
            <Box
              sx={{
                width: ['48%', '48%', '48%', '23%', '23%', '23%'],
                textAlign: 'center',
              }}
            >
              <img
                src="/database.png"
                alt="database"
                sx={{
                  objectFit: 'contain',
                  maxWidth: '100%',
                }}
              />
              <p sx={{ fontWeight: '600' }}>データベース</p>
            </Box>

            <Box
              sx={{
                width: ['48%', '48%', '48%', '23%', '23%', '23%'],
                textAlign: 'center',
              }}
            >
              <img
                src="/infra.png"
                alt="infra"
                sx={{
                  objectFit: 'contain',
                  maxWidth: '100%',
                }}
              />
              <p sx={{ fontWeight: '600' }}>インフラ</p>
            </Box>
            <Box
              sx={{
                width: ['48%', '48%', '48%', '23%', '23%', '23%'],
                textAlign: 'center',
              }}
            >
              <img
                src="/security.png"
                alt="security"
                sx={{
                  objectFit: 'contain',
                  maxWidth: '100%',
                }}
              />
              <p sx={{ fontWeight: '600' }}>セキュリティ</p>
            </Box>
          </Box>
        </Box>

        {/* ここからマーク ３行目*/}
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              whiteSpace: 'nowrap',
              fontSize: ['0.7rem', '0.8rem', '0.9rem'],
            }}
          >
            <Box
              sx={{
                width: ['48%', '48%', '48%', '23%', '23%', '23%'],
                textAlign: 'center',
              }}
            >
              <img
                src="/Network.png"
                alt="Network"
                sx={{
                  objectFit: 'contain',
                  maxWidth: '100%',
                }}
              />
              <p sx={{ fontWeight: '600' }}>ネットワーク</p>
            </Box>

            <Box
              sx={{
                width: ['48%', '48%', '48%', '23%', '23%', '23%'],
                textAlign: 'center',
              }}
            >
              <img
                src="/serverengineer.png"
                alt="serverengineer"
                sx={{
                  objectFit: 'contain',
                  maxWidth: '100%',
                }}
              />
              <p sx={{ fontWeight: '600' }}>サーバーエンジニア</p>
            </Box>
            <Box
              sx={{
                width: ['48%', '48%', '48%', '23%', '23%', '23%'],
                textAlign: 'center',
              }}
            >
              <img
                src="/webdesigner.png"
                alt="webdesigner"
                sx={{
                  objectFit: 'contain',
                  maxWidth: '100%',
                }}
              />
              <p sx={{ fontWeight: '600' }}>WEBデザイナー</p>
            </Box>
          </Box>
        </Box>

        {/* ここから最高制度 */}
        <Box
          sx={{
            fontSize: ['2rem', '2rem', '3rem'],
            fontWeight: 700,
            lineHeight: 1.25,
            letterSpacing: '0.025em',
            color: 'text',
            textAlign: ['center'],
            flex: '1',
          }}
        >
          <p>
            evaluation for come up
            <br />
            <span
              sx={{
                color: 'skyblue',
                fontSize: ['1.5rem', '1.5rem', '1.5rem'],
                margin: '0px',
              }}
            >
              活躍を応援する制度
            </span>
          </p>
        </Box>

        {/* 制度１行目 */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            flex: '1',
            maxWidth: '960px',
            margin: '0 auto',
            gap: '0 45px',
          }}
        >
          <Box
            sx={{
              padding: '16px',
              border: '2px solid white',
              borderRadius: '20px',
              backgroundColor: 'white',
              maxWidth: '230px',
              height: '300px',
              margin: '10px auto',
              textAlign: 'center',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.02)',
              flexDirection: ['column', 'column', 'row', 'row', 'row'],
            }}
          >
            <img
              src="/ceotalk.png"
              alt="ceotalk"
              sx={{
                objectFit: 'contain',
                maxWidth: '100%',
                marginTop: '20px',
              }}
            />
            <Box
              sx={{
                fontWeight: '800',
                fontSize: ['13px', '14px', '17px'],
                marginBottom: '8px',
              }}
            >
              CEO Talk
            </Box>

            <Box
              sx={{
                wordWrap: 'break-word',
                fontWeight: '600',
                fontSize: '13px',
                marginBottom: '8px',
              }}
            >
              経営Topが何を考え、何を目指しているのか。そのような話を少人数の場で直接CEOから聞くことで、視座を上げ経営目線でモノゴを捉えるサポートをしています。
            </Box>
          </Box>

          <Box
            sx={{
              padding: '16px',
              border: '2px solid white',
              borderRadius: '20px',
              backgroundColor: 'white',
              maxWidth: '230px',
              height: '300px',
              margin: '10px auto',
              textAlign: 'center',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.02)',
              flexDirection: ['column', 'column', 'row', 'row', 'row'],
            }}
          >
            <img
              src="/mentor.png"
              alt="mentor"
              sx={{
                objectFit: 'contain',
                maxWidth: '100%',
                marginTop: '20px',
              }}
            />
            <Box
              sx={{
                fontWeight: '800',
                fontSize: ['13px', '14px', '17px'],
                marginBottom: '8px',
              }}
            >
              メンター制度
            </Box>

            <Box
              sx={{
                wordWrap: 'break-word',
                fontWeight: '600',
                fontSize: '13px',
                marginBottom: '8px',
              }}
            >
              経験豊富な先輩がメンターとなり、後輩のコンディションを把握しながら、悩みの解決に向けた手助けをするための仕組みです。
            </Box>
          </Box>

          <Box
            sx={{
              padding: '16px',
              border: '2px solid white',
              borderRadius: '20px',
              backgroundColor: 'white',
              maxWidth: '230px',
              height: '300px',
              margin: '10px auto',
              textAlign: 'center',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.02)',
              flexDirection: ['column', 'column', 'row', 'row', 'row'],
            }}
          >
            <img
              src="/freelance.png"
              alt="freelance"
              sx={{
                objectFit: 'contain',
                maxWidth: '100%',
                marginTop: '20px',
              }}
            />
            <Box
              sx={{
                fontWeight: '800',
                fontSize: ['13px', '14px', '17px'],
                marginBottom: '8px',
              }}
            >
              フリーランス平均還元率、業界高水準の９５％！あなたの高い技術力を弊社は高く評価しています。
            </Box>
          </Box>
        </Box>

        {/* 制度２行目 */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            flex: '1',
            maxWidth: '960px',
            margin: '0 auto',
            gap: '0 120px',
          }}
        >
          <Box
            sx={{
              padding: '16px',
              border: '2px solid white',
              borderRadius: '20px',
              backgroundColor: 'white',
              maxWidth: '230px',
              height: '300px',
              margin: '10px auto',
              textAlign: 'center',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.02)',
              flexDirection: ['column', 'column', 'row', 'row', 'row'],
            }}
          >
            <img
              src="/studycontents.png"
              alt="studycontents"
              sx={{
                objectFit: 'contain',
                maxWidth: '100%',
                marginTop: '20px',
              }}
            />
            <Box
              sx={{
                fontWeight: '800',
                fontSize: ['13px', '14px', '17px'],
                marginBottom: '8px',
              }}
            >
              教育支援
            </Box>

            <Box
              sx={{
                wordWrap: 'break-word',
                fontWeight: '600',
                fontSize: '13px',
                marginBottom: '8px',
              }}
            >
              学習コンテンツの購入負担や、技術関連資格取得サポートを会社側で行います。
            </Box>
          </Box>

          <Box
            sx={{
              padding: '16px',
              border: '2px solid white',
              borderRadius: '20px',
              backgroundColor: 'white',
              maxWidth: '230px',
              height: '300px',
              margin: '10px auto',
              textAlign: 'center',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.02)',
            }}
          >
            <img
              src="/1on1.png"
              alt="1on1"
              sx={{
                objectFit: 'contain',
                maxWidth: '100%',
              }}
            />
            <Box
              sx={{
                fontWeight: '800',
                fontSize: ['13px', '14px', '17px'],
                marginBottom: '8px',
              }}
            >
              1on1制度
            </Box>

            <Box
              sx={{
                wordWrap: 'break-word',
                fontWeight: '600',
                fontSize: '13px',
                marginBottom: '8px',
              }}
            >
              1対1の面談を通して、業務内/業務外にかかわらず、悩みや困りごとを気軽に相談し、解決のためのヒントを得ることを目的とした場です。
            </Box>
          </Box>

          <Box
            sx={{
              padding: '16px',
              border: '2px solid white',
              borderRadius: '20px',
              backgroundColor: 'white',
              maxWidth: '230px',
              height: '300px',
              margin: '10px auto',
              textAlign: 'center',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.02)',
              // marginLeft: '0px',
            }}
          >
            <img
              src="/coffee.png"
              alt="coffee"
              sx={{
                objectFit: 'contain',
                maxWidth: '100%',
                marginTop: '20px',
              }}
            />
            <Box
              sx={{
                fontWeight: '800',
                fontSize: ['13px', '14px', '15px'],
                marginBottom: '8px',
              }}
            >
              フリースナック＆ドリンク
            </Box>

            <Box
              sx={{
                wordWrap: 'break-word',
                fontWeight: '600',
                fontSize: '13px',
                marginBottom: '8px',
              }}
            >
              オフィス内に、コーヒーやお茶、お水、紅茶などご用意しています。お好きなだけお召し上がりください。
            </Box>
          </Box>
        </Box>
        <p
          sx={{
            fontSize: '8px',
            textAlign: 'right',
          }}
        >
          アイコン提供:
          <a
            href="https://icons8.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Icons8
          </a>
        </p>

        {/* ここから福利厚生 */}
        <Box
          sx={{
            fontSize: ['2rem', '2rem', '3rem'],
            fontWeight: 700,
            lineHeight: 1.25,
            letterSpacing: '0.025em',
            color: 'text',
            textAlign: ['center'],
            flex: '1',
          }}
        >
          <p>
            welfare
            <br />
            <span
              sx={{
                color: 'skyblue',
                fontSize: ['1.5rem', '1.5rem', '1.5rem'],
                margin: '0px',
              }}
            >
              福利厚生
            </span>
          </p>
        </Box>

        {/* 福利厚生１行目 */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            flex: '1',
            maxWidth: '960px',
            margin: '0 auto',
            gap: '0 45px',
          }}
        >
          <Box
            sx={{
              padding: '16px',
              border: '2px solid white',
              borderRadius: '20px',
              backgroundColor: 'white',
              maxWidth: '230px',
              height: '300px',
              margin: '16px auto',
              textAlign: 'center',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.02)',
              flexDirection: ['column', 'column', 'row', 'row', 'row'],
            }}
          >
            <img
              src="/remote.png"
              alt="remote"
              sx={{
                objectFit: 'contain',
                maxWidth: '100%',
                marginTop: '20px',
              }}
            />
            <Box
              sx={{
                fontWeight: '800',
                fontSize: ['13px', '14px', '17px'],
                marginBottom: '8px',
              }}
            >
              リモート勤務
            </Box>

            <Box
              sx={{
                wordWrap: 'break-word',
                fontWeight: '600',
                fontSize: '13px',
                marginBottom: '8px',
              }}
            >
              家庭と仕事を両立をするために、柔軟にリモート業務へ切り替えることできます。
            </Box>
          </Box>

          <Box
            sx={{
              padding: '16px',
              border: '2px solid white',
              borderRadius: '20px',
              backgroundColor: 'white',
              maxWidth: '230px',
              height: '300px',
              margin: '16px auto',
              textAlign: 'center',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.02)',
            }}
          >
            <img
              src="/wedding.png"
              alt="wedding"
              sx={{
                objectFit: 'contain',
                maxWidth: '100%',
                marginTop: '20px',
              }}
            />
            <Box
              sx={{
                fontWeight: '800',
                fontSize: ['13px', '14px', '17px'],
                marginBottom: '8px',
              }}
            >
              ハッピーウェディング
            </Box>

            <Box
              sx={{
                wordWrap: 'break-word',
                fontWeight: '600',
                fontSize: '13px',
                marginBottom: '8px',
              }}
            >
              結婚した社員に対して、挙式や新婚旅行のための休暇と支援金を付与し、人生の一大イベントを祝う制度です。
            </Box>
          </Box>

          <Box
            sx={{
              padding: '16px',
              border: '2px solid white',
              borderRadius: '20px',
              backgroundColor: 'white',
              maxWidth: '230px',
              height: '300px',
              margin: '16px auto',
              textAlign: 'center',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.02)',
            }}
          >
            <img
              src="/cheers.png"
              alt="cheers"
              sx={{
                objectFit: 'contain',
                maxWidth: '100%',
                marginTop: '20px',
              }}
            />
            <Box
              sx={{
                fontWeight: '800',
                fontSize: ['13px', '14px', '17px'],
                marginBottom: '8px',
              }}
            >
              部門懇親会費用補助
            </Box>

            <Box
              sx={{
                wordWrap: 'break-word',
                fontWeight: '600',
                fontSize: '13px',
                marginBottom: '8px',
              }}
            >
              社員間のコミュニケーションを活性化させ、組織の一体感を向上したい。そんな思いを込めて懇親会予算を支給します。
            </Box>
          </Box>
        </Box>

        {/* 福利厚生２行目 */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            flex: '1',
            maxWidth: '960px',
            margin: '0 auto',
            gap: '0 120px',
          }}
        >
          <Box
            sx={{
              padding: '16px',
              border: '2px solid white',
              borderRadius: '20px',
              backgroundColor: 'white',
              maxWidth: '230px',
              height: '300px',
              margin: '16px auto',
              textAlign: 'center',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.02)',
              flexDirection: ['column', 'column', 'row', 'row', 'row'],
            }}
          >
            <img
              src="/lunch.png"
              alt="lunch"
              sx={{
                objectFit: 'contain',
                maxWidth: '100%',
                marginTop: '20px',
              }}
            />
            <Box
              sx={{
                fontWeight: '800',
                fontSize: ['13px', '14px', '17px'],
                marginBottom: '8px',
              }}
            >
              ランチ補助
            </Box>

            <Box
              sx={{
                wordWrap: 'break-word',
                fontWeight: '600',
                fontSize: '13px',
                marginBottom: '8px',
              }}
            >
              仲間との親睦を深め、情報共有の役立ちに貢献します。
              <br />
              １人千円まで補助
            </Box>
          </Box>

          <Box
            sx={{
              padding: '16px',
              border: '2px solid white',
              borderRadius: '20px',
              backgroundColor: 'white',
              maxWidth: '230px',
              height: '300px',
              margin: '16px auto',
              textAlign: 'center',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.02)',
            }}
          >
            <img
              src="/health.png"
              alt="health"
              sx={{
                objectFit: 'contain',
                maxWidth: '100%',
                marginTop: '30px',
              }}
            />
            <Box
              sx={{
                fontWeight: '800',
                fontSize: ['13px', '14px', '17px'],
                marginBottom: '8px',
              }}
            >
              健康診断
            </Box>

            <Box
              sx={{
                wordWrap: 'break-word',
                fontWeight: '600',
                fontSize: '13px',
                marginBottom: '8px',
              }}
            >
              経験豊富な先輩がメンターとなり、後輩のコンディションを把握しながら、悩みの解決に向けた手助けをするための仕組みです。
            </Box>
          </Box>

          <Box
            sx={{
              padding: '16px',
              border: '2px solid white',
              borderRadius: '20px',
              backgroundColor: 'white',
              maxWidth: '230px',
              height: '300px',
              margin: '16px auto',
              textAlign: 'center',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.02)',
            }}
          >
            <img
              src="/vacation.png"
              alt="vacation"
              sx={{
                objectFit: 'contain',
                maxWidth: '100%',
                marginTop: '20px',
              }}
            />
            <Box
              sx={{
                fontWeight: '800',
                fontSize: ['13px', '14px', '17px'],
                marginBottom: '8px',
              }}
            >
              特別休暇
            </Box>

            <Box
              sx={{
                wordWrap: 'break-word',
                fontWeight: '600',
                fontSize: '13px',
                marginBottom: '8px',
              }}
            >
              育児・介護・記念日などの特別な用事に休暇を与えます。
            </Box>
          </Box>
        </Box>
        <p
          sx={{
            fontSize: '8px',
            textAlign: 'right',
          }}
        >
          アイコン提供:
          <a
            href="https://icons8.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Icons8
          </a>
        </p>
      </Box>
    </React.Fragment>
  )
}
